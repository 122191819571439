import { styled } from 'styled-components';
import HNText from '../HNText';
import { Fonts } from '@/styles/fonts';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

const HowItWorksSection = ({ inView }: { inView: boolean }) => {
    return (
        <WhyChooseContainer>
            <StyledTitle font={Fonts.SecularOne} $inView={inView}>
                איך זה עובד
            </StyledTitle>
            <StatementsContainer>
                <AnimatedStep statement="1. בחרו תבנית מהקטלוג" videoUrl="https://storage.googleapis.com/hazmanina-static/1.gif?" />
                <AnimatedStep statement="2. ערכו את ההזמנה" videoUrl="https://storage.googleapis.com/hazmanina-static/2.gif" />
                <AnimatedStep
                    statement="3. לאחר תשלום, הורידו את ההזמנה ושלחו לאורחים"
                    videoUrl="https://storage.googleapis.com/hazmanina-static/3.gif"
                />
            </StatementsContainer>
        </WhyChooseContainer>
    );
};

export default HowItWorksSection;

const WhyChooseContainer = styled.div`
    width: 100%;
    border-radius: 6px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledTitle = styled(HNText)<{ $inView: boolean }>`
    font-size: 100px;

    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease-in-out;
`;

const ZOOM = 1.7;
const THRESHOLD = 0.6;

const AnimatedStep = ({ statement, videoUrl }: { statement: string; videoUrl: string }) => {
    const ref = useRef(null);
    const [inView, setInView] = useState(false);

    useEffect(() => {
        let refValue = null;

        const createObserver = (setState: React.Dispatch<React.SetStateAction<boolean>>, threshold = THRESHOLD) => {
            return new IntersectionObserver(([entry]) => setState(entry.isIntersecting), { threshold });
        };
        const observer = createObserver(setInView);

        if (ref.current) {
            observer.observe(ref.current);
            refValue = ref.current;
        }

        return () => {
            if (refValue) observer.unobserve(refValue);
        };
    }, []);

    return (
        <StatementContainer $inView={inView} ref={ref}>
            <Statement font={Fonts.Alef}>{statement}</Statement>
            <StyledGif src={videoUrl} width={382 * ZOOM} height={176 * ZOOM} alt={statement} />
        </StatementContainer>
    );
};

const StyledGif = styled(Image)`
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
`;

const StatementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
    margin-block-start: 50px;
    margin-block-end: 100px;
`;

const StatementContainer = styled.div<{ $inView: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 15px 30px 30px 30px;
    margin-block-end: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    transform: ${({ $inView }) => ($inView ? 'translateY(0)' : 'translateY(10px)')};
    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease, transform 1s ease;
`;

const Statement = styled(HNText)`
    text-align: center;
    font-size: 23px;
    opacity: 0.87;
    margin-block-end: 15px;
`;
