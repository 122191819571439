import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';

const FloatingImages: React.FC<{ imagePaths: string[] }> = ({ imagePaths }) => {
    const [images, setImages] = useState<
        {
            id: string;
            x: number;
            y: number;
            zoom: number;
            url: string;
            originalX: number;
            originalY: number;
            isPushed: boolean;
        }[]
    >([]);

    useEffect(() => {
        const scatteredImages = imagePaths.map((url, index) => {
            const x = Math.random() * 100;
            const y = Math.random() * 100;
            return {
                id: `${index}`,
                x,
                y,
                originalX: x,
                originalY: y,
                zoom: 2 * Math.random(),
                url,
                isPushed: false
            };
        });
        setImages(scatteredImages);
    }, [imagePaths]);

    const handlePush = (id: string) => {
        setImages(prevImages =>
            prevImages.map(img =>
                img.id === id
                    ? {
                          ...img,
                          isPushed: true
                      }
                    : img
            )
        );

        setTimeout(() => {
            setImages(prevImages =>
                prevImages.map(img =>
                    img.id === id
                        ? {
                              ...img,
                              x: Math.random() * 50,
                              y: Math.random() * 50,
                              isPushed: false
                          }
                        : img
                )
            );
        }, 1000);
    };

    return (
        <>
            {images.map(({ id, x, y, zoom, url, isPushed }) => (
                <FloatingImage key={id} x={x} y={y} zoom={zoom} isPushed={isPushed} onClick={() => handlePush(id)}>
                    <Image src={url} alt={`Floating background ${id}`} width={100} height={100} />
                </FloatingImage>
            ))}
        </>
    );
};

const bounceAnimation = keyframes`
  0% { transform: scale(1.2); }
  30% { transform: scale(0.9); }
  50% { transform: scale(1.05); }
  80% { transform: scale(0.98); }
  100% { transform: scale(1); }
`;

const FloatingImage = styled.div<{ x: number; y: number; zoom: number; isPushed: boolean }>`
    position: absolute;
    top: ${({ y }) => y}%;
    left: ${({ x }) => x}%;
    transform: ${({ isPushed }) => (isPushed ? 'scale(0)' : 'scale(1)')};
    animation: ${({ isPushed }) =>
        !isPushed
            ? css`
                  ${bounceAnimation} 1s ease-out
              `
            : 'none'};
    transition: ${({ isPushed }) => (isPushed ? 'transform 0.5s ease-out' : 'none')};
    zoom: ${({ zoom }) => zoom};
    cursor: pointer;
    z-index: 1;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
`;

export default FloatingImages;
