import { colors } from '@/styles/colors';
import { styled } from 'styled-components';
import HNText from '../HNText';
import { Fonts } from '@/styles/fonts';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useEffect, useRef, useState } from 'react';
import FloatingImages from './FloatingImages';
import config from '@/lib/config';

const previewFileName = (file: string) => `${config.templates.templatePreviewBaseUrl}/${file}.png`;
const images = [
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds'),
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds'),
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds'),
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds'),
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds'),
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds')
];
const WhyUsSection = ({ inView }: { inView: boolean }) => {
    return (
        <WhyChooseContainer>
            <StyledTitle font={Fonts.SecularOne} $inView={inView}>
                למה הזמנינא?
            </StyledTitle>
            <StatementsContainer>
                <FloatingImages imagePaths={images} />
                <StatementsInnerContainer>
                    <AnimatedStatement statement="מבחר תבניות מעוצבות" index={0} />
                    <AnimatedStatement statement="עיצוב אישי ונוח" index={1} />
                    <AnimatedStatement statement="מחירים משתלמים" index={2} />
                    <AnimatedStatement statement="שירות לקוחות מסור" index={3} />
                </StatementsInnerContainer>
            </StatementsContainer>
        </WhyChooseContainer>
    );
};

export default WhyUsSection;

const WhyChooseContainer = styled.div`
    position: relative;
    width: 80%;
    background-color: ${colors.white};
    border-radius: 6px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    overflow: hidden;
    padding: 40px 100px;
    justify-self: center;
    margin-block-end: 80px;
`;

const StyledTitle = styled(HNText)<{ $inView: boolean }>`
    font-size: 100px;

    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease-in-out;
    z-index: 2;
    position: relative;
    display: inline-block;
    line-height: 80px;
    pointer-events: none;
`;

const THRESHOLD = 0.6;
const DELAY_COEFFICIENT = 0.2;

const AnimatedStatement = ({ statement, index }: { statement: string; index: number }) => {
    const ref = useRef(null);
    const [inView, setInView] = useState(false);

    useEffect(() => {
        let refValue = null;

        const createObserver = (setState: React.Dispatch<React.SetStateAction<boolean>>, threshold = THRESHOLD) => {
            return new IntersectionObserver(([entry]) => setState(entry.isIntersecting), { threshold });
        };

        const observer = createObserver(setInView);

        if (ref.current) {
            observer.observe(ref.current);
            refValue = ref.current;
        }

        return () => {
            if (refValue) observer.unobserve(refValue);
        };
    }, [inView]);

    return (
        <StatementContainer $inView={inView} $delay={index * DELAY_COEFFICIENT} ref={ref}>
            <IoCheckmarkCircle color={colors.success} size={32} />
            <Statement font={Fonts.Alef}>{statement}</Statement>
        </StatementContainer>
    );
};

const StatementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StatementsInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
    height: 400px;
    position: relative;
`;

const StatementContainer = styled.div<{ $inView: boolean; $delay: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.primary};
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 300px;
    z-index: 2;

    transform: ${({ $inView }) => ($inView ? 'translateX(0)' : 'translateX(400px)')};
    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: ${({ $delay }) => $delay}s;
`;

const Statement = styled(HNText)`
    text-align: center;
    font-size: 23px;
    margin-inline-start: 10px;
`;
