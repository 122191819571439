import { styled, css, keyframes } from 'styled-components';
import config from '@/lib/config';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

const previewFileName = (file: string) => `${config.templates.templatePreviewBaseUrl}/${file}.png`;

const images = [
    previewFileName('pink_flowers_background'),
    previewFileName('grean_leavs'),
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('love_birds')
];

const IMAGE_WIDTH_PX = 300;
const IMAGE_HEIGHT_PX = 300 * (7 / 5);
const GAP_PX = 20;
const IMAGE_WITH_GAP_PX = IMAGE_WIDTH_PX + GAP_PX;
const IMAGES_COUNT = images.length;
const STRIP_WIDTH_PX = IMAGE_WITH_GAP_PX * IMAGES_COUNT;
const loopTime = 20;

const ImageStrip = ({ reversed, className }: { reversed?: boolean; className?: string }) => {
    const ref = useRef(null);
    const [inView, setInView] = useState(false);

    useEffect(() => {
        let refValue = null;

        const createObserver = (setState: React.Dispatch<React.SetStateAction<boolean>>, threshold = 0.5) => {
            return new IntersectionObserver(([entry]) => setState(entry.isIntersecting), { threshold });
        };

        const observer = createObserver(setInView);

        if (ref.current) {
            observer.observe(ref.current);
            refValue = ref.current;
        }

        return () => {
            if (refValue) observer.unobserve(refValue);
        };
    }, []);

    return (
        <StripContainer className={`${className}`} ref={ref} $inView={inView}>
            <ImageStripContainer reverse={reversed}>
                {images.concat(images).map((src, index) => (
                    <StripImage key={index} src={src} alt={`Image ${index}`} />
                ))}
            </ImageStripContainer>
        </StripContainer>
    );
};

const StripContainer = styled.div<{ $inView: boolean }>`
    position: relative;
    width: 100%;
    overflow: hidden;

    transform: ${({ $inView }) => ($inView ? 'translateY(0)' : 'translateY(10px)')};
    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease, transform 1s ease;
`;

const createScrollAnimation = (reverse: boolean) => keyframes`
    0% {
        transform: ${reverse ? `translateX(0)` : `translateX(${STRIP_WIDTH_PX}px)`};
    }
    100% {
        transform: ${reverse ? `translateX(${STRIP_WIDTH_PX}px)` : `translateX(0)`};
    }
`;

const ImageStripContainer = styled.div<{ reverse?: boolean }>`
    display: flex;
    align-items: center;
    width: ${STRIP_WIDTH_PX}px;
    gap: ${GAP_PX}px;
    animation: ${({ reverse }) =>
        css`
            ${createScrollAnimation(reverse || false)} ${loopTime}s linear infinite
        `};
`;

const StripImage = styled(Image).attrs({
    width: IMAGE_WIDTH_PX,
    height: IMAGE_HEIGHT_PX
})`
    flex-shrink: 0;
    width: ${IMAGE_WIDTH_PX}px;
    height: ${IMAGE_HEIGHT_PX}px;
    object-fit: cover;
    border-radius: 6px;
`;

export default ImageStrip;
